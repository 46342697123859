import { FC, useState } from "react";

const SelectedItems: FC<any> = ({
  items,
  removeSelectedItem,
  screen,
  setScreen,
  totalPrice,
  increaseQuantity,
  decreaseQuantity,
}) => {
  const [openContainer, setOpenContainer] = useState<boolean>(true);

  return (
    <div className=" p-3 sm:p-6 md:p-14  bg-slate-100 rounded-md mb-10">
      <div className=" flex items-center justify-end px-0.5">
        <div className="flex mr-4">
          <p className=" text-right text-base font-bold">{"Total Payout:  "}</p>
          <p className=" text-base font-bold text-gray-500 ml-3">
            $ {totalPrice ?? "0"}
          </p>
        </div>

        {["categories", "sub-categories", "products"].includes(screen) && (
          <button
            onClick={() => setScreen("list")}
            className="payButton shadow"
          >
            <span className="buttonDecor"></span>
            <div className="buttonContent">
              <div className="buttonIcon">
                <svg
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                >
                  <circle
                    opacity="0.5"
                    cx="25"
                    cy="25"
                    r="23"
                    fill="url(#icon-payments-cat_svg__paint0_linear_1141_21101)"
                  ></circle>
                  <mask id="icon-payments-cat_svg__a" fill="#fff">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
                    ></path>
                  </mask>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.42 15.93c.382-1.145-.706-2.234-1.851-1.852l-18.568 6.189c-1.186.395-1.362 2-.29 2.644l5.12 3.072a1.464 1.464 0 001.733-.167l5.394-4.854a1.464 1.464 0 011.958 2.177l-5.154 4.638a1.464 1.464 0 00-.276 1.841l3.101 5.17c.644 1.072 2.25.896 2.645-.29L34.42 15.93z"
                    fill="#fff"
                  ></path>
                  <path
                    d="M25.958 20.962l-1.47-1.632 1.47 1.632zm2.067.109l-1.632 1.469 1.632-1.469zm-.109 2.068l-1.469-1.633 1.47 1.633zm-5.154 4.638l-1.469-1.632 1.469 1.632zm-.276 1.841l-1.883 1.13 1.883-1.13zM34.42 15.93l-2.084-.695 2.084.695zm-19.725 6.42l18.568-6.189-1.39-4.167-18.567 6.19 1.389 4.166zm5.265 1.75l-5.12-3.072-2.26 3.766 5.12 3.072 2.26-3.766zm2.072 3.348l5.394-4.854-2.938-3.264-5.394 4.854 2.938 3.264zm5.394-4.854a.732.732 0 01-1.034-.054l3.265-2.938a3.66 3.66 0 00-5.17-.272l2.939 3.265zm-1.034-.054a.732.732 0 01.054-1.034l2.938 3.265a3.66 3.66 0 00.273-5.169l-3.265 2.938zm.054-1.034l-5.154 4.639 2.938 3.264 5.154-4.638-2.938-3.265zm1.023 12.152l-3.101-5.17-3.766 2.26 3.101 5.17 3.766-2.26zm4.867-18.423l-6.189 18.568 4.167 1.389 6.19-18.568-4.168-1.389zm-8.633 20.682c1.61 2.682 5.622 2.241 6.611-.725l-4.167-1.39a.732.732 0 011.322-.144l-3.766 2.26zm-6.003-8.05a3.66 3.66 0 004.332-.419l-2.938-3.264a.732.732 0 01.866-.084l-2.26 3.766zm3.592-1.722a3.66 3.66 0 00-.69 4.603l3.766-2.26c.18.301.122.687-.138.921l-2.938-3.264zm11.97-9.984a.732.732 0 01-.925-.926l4.166 1.389c.954-2.861-1.768-5.583-4.63-4.63l1.39 4.167zm-19.956 2.022c-2.967.99-3.407 5.003-.726 6.611l2.26-3.766a.732.732 0 01-.145 1.322l-1.39-4.167z"
                    fill="#fff"
                    mask="url(#icon-payments-cat_svg__a)"
                  ></path>
                  <defs>
                    <linearGradient
                      id="icon-payments-cat_svg__paint0_linear_1141_21101"
                      x1="25"
                      y1="2"
                      x2="25"
                      y2="48"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#fff" stop-opacity="0.71"></stop>
                      <stop
                        offset="1"
                        stop-color="#fff"
                        stop-opacity="0"
                      ></stop>
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <span className="buttonText">Get Paid</span>
            </div>
          </button>
        )}

        <button
          onClick={() => setOpenContainer(!openContainer)}
          className="p-3"
        >
          {openContainer ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
              viewBox="0 0 512 298.04"
            >
              <path
                fillRule="nonzero"
                d="M70.94 285.81c-16.17 16.24-42.46 16.32-58.71.15-16.24-16.16-16.32-42.46-.15-58.7L226.57 12.23c16.16-16.24 42.46-16.32 58.7-.15l214.65 215.18c16.17 16.24 16.09 42.54-.15 58.7-16.25 16.17-42.54 16.09-58.71-.15L256 100.29 70.94 285.81z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
              viewBox="0 0 512 298.04"
            >
              <path
                fillRule="nonzero"
                d="M12.08 70.78c-16.17-16.24-16.09-42.54.15-58.7 16.25-16.17 42.54-16.09 58.71.15L256 197.76 441.06 12.23c16.17-16.24 42.46-16.32 58.71-.15 16.24 16.16 16.32 42.46.15 58.7L285.27 285.96c-16.24 16.17-42.54 16.09-58.7-.15L12.08 70.78z"
              />
            </svg>
          )}
        </button>
      </div>

      {openContainer && (
        <div
          className={`itemContainer ${
            openContainer ? "slide-down " : "slide-up "
          }`}
        >
          {items.map((item: any, index: number) => {
            return (
              <div key={index} className=" my-5 rounded-lg p-3 px-2 justify-between flex items-center bg-white shadow">
                <div className="flex items-center">
                  <div className="h-20 w-20 md:h-[70px] md:w-[70px] mr-3 ">
                    <img
                      src={`http://127.0.0.1:8000${item.image}`}
                      alt="Smartphone"
                      className=" rounded-t-lg object-contain h-auto"
                    />
                  </div>
                  <div>
                    <p className="text-xs sm:text-sm md:text-base text-left font-bold text-black">
                      {item.name}
                    </p>
                    <p className=" text-xs text-left font-light text-gray-500 my-1">
                      Price ${item.variants.price}
                    </p>
                    <p className=" text-xs text-left font-medium text-black">
                      {item.attributes}
                    </p>
                  </div>
                </div>

                <div className="flex  relative">
                  <div className="flex  justify-center items-center">
                    <button
                      onClick={() => decreaseQuantity(index)}
                      className=" bg-white shadow hover:shadow-md hover:shadow-slate-400 flex justify-center items-center h-6 w-6 sm:w-8 sm:h-8 rounded-md text-black"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="15px"
                        height="9.737px"
                        viewBox="0 0 122.881 9.737"
                        enableBackground="new 0 0 122.881 9.737"
                      >
                        <g>
                          <path d="M117.922,0.006C117.951,0.002,117.982,0,118.012,0c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017 c0.584,0.248,1.107,0.603,1.543,1.039c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859 c-0.248,0.6-0.609,1.137-1.057,1.583c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37 c-0.029,0-0.061-0.002-0.09-0.006c-37.654,0-75.309,0.001-112.964,0.001c-0.029,0.004-0.059,0.006-0.09,0.006 c-0.654,0-1.283-0.132-1.859-0.371c-0.6-0.248-1.137-0.609-1.583-1.056C0.981,7.865,0.621,7.33,0.372,6.73H0.371 C0.132,6.154,0,5.525,0,4.869C0,4.215,0.132,3.586,0.371,3.01c0.249-0.6,0.61-1.137,1.056-1.583 c0.881-0.881,2.098-1.426,3.442-1.426c0.031,0,0.061,0.002,0.09,0.006C42.613,0.006,80.268,0.006,117.922,0.006L117.922,0.006z" />
                        </g>
                      </svg>
                    </button>
                    <span className="p-1  sm:p-4 text-black">
                      {item.quantity}
                    </span>
                    <button
                      onClick={() => increaseQuantity(index)}
                      className=" bg-white shadow hover:shadow-md hover:shadow-slate-400  flex justify-center items-center h-6 w-6 sm:w-8 sm:h-8 rounded-md text-black"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="15px"
                        height="15px"
                        viewBox="0 0 122.881 122.88"
                        enableBackground="new 0 0 122.881 122.88"
                      >
                        <g>
                          <path d="M56.573,4.868c0-0.655,0.132-1.283,0.37-1.859c0.249-0.6,0.61-1.137,1.056-1.583C58.879,0.545,60.097,0,61.44,0 c0.658,0,1.287,0.132,1.863,0.371c0.012,0.005,0.023,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.426,2.098,1.426,3.442c0,0.03-0.002,0.06-0.006,0.089v51.62l51.619,0c0.029-0.003,0.061-0.006,0.09-0.006 c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859c-0.248,0.6-0.609,1.137-1.057,1.583 c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37c-0.029,0-0.061-0.002-0.09-0.006l-51.619,0.001 v51.619c0.004,0.029,0.006,0.06,0.006,0.09c0,0.656-0.133,1.286-0.371,1.861c-0.006,0.014-0.012,0.025-0.018,0.037 c-0.248,0.584-0.602,1.107-1.037,1.543c-0.883,0.882-2.1,1.427-3.443,1.427c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.444-0.806-0.98-1.055-1.58h-0.001c-0.239-0.575-0.371-1.205-0.371-1.861 c0-0.03,0.002-0.061,0.006-0.09V66.303H4.958c-0.029,0.004-0.059,0.006-0.09,0.006c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.445-0.806-0.98-1.055-1.58H0.371C0.132,62.726,0,62.097,0,61.44 c0-0.655,0.132-1.283,0.371-1.859c0.249-0.6,0.61-1.137,1.056-1.583c0.881-0.881,2.098-1.426,3.442-1.426 c0.031,0,0.061,0.002,0.09,0.006l51.62,0l0-51.62C56.575,4.928,56.573,4.898,56.573,4.868L56.573,4.868z" />
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div className="hidden md:block">
                    <div className=" flex md:w-40  justify-end  pr-2">
                      <button
                        onClick={() => {
                          removeSelectedItem(item.id);
                        }}
                        className="deleteButton"
                      >
                        <svg viewBox="0 0 448 512" className="svgIcon">
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="block md:hidden">
                    <div className=" absolute bottom-10 h-5 w-5 rounded-sm bg-red-400 flex justify-center items-center right-0">
                      <button
                        onClick={() => {
                          removeSelectedItem(item.id);
                        }}
                        className=" text-white text-xs"
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectedItems;
