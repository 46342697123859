import React, { useState } from "react";
import { Collapsible, ConditionsCollapsible } from "./Collapsible";

const data2 = [
  {
    name: "New",
    subName:
      "Your device is in this condition if ALL of the following are true",
    description: [
      "100% Functional & brand new in a sealed box",
      "Plastic film must be still be present on the device",
      "All original accessories that come with a brand new device must be present",
      "Clear ESN / IMEI for activation by a new user",
      "Please Note: Any iPhone reported as lost or stolen, locked to an iCloud or Find My iPhone account, or attached to an MDM / DEP / or IT Policy is not eligible for trade-in.",
    ],
  },
  {
    name: "Excellent",
    subName:
      "Your device is in this condition if ALL of the following are true:",
    description: [
      "Your iPhone is 100% functional and appears 'like new' or has minor cosmetic wear",
      "Powers on, holds a charge, and is able to make calls",
      "Screen and body are free of any cracks, chips, or major scratches",
      "LCD screen is free of any cracks, blemishes, or discoloration",
      "Original Battery above 80% capacity",
      "All buttons are present and fully functional (power, home, ringer, mute, and/or volume)",
      "All features are fully functional (WiFi, touchscreen, mic, speaker, camera, earpiece, etc)",
      "Clear ESN / IMEI for activation by a new user. Find My iPhone must be turned off.",
      "Please Note: Any iPhone reported as lost or stolen, locked to an iCloud or Find My iPhone account, or attached to an MDM / DEP / or IT Policy is not eligible for trade-in.",
    ],
  },
  {
    name: "Average",
    subName:
      "Your device is in this condition if ALL of the following are true:",
    description: [
      "Your iPhone is 100% functional with signs of normal cosmetic wear from use",
      "Powers on, holds a charge, and is able to make calls",
      "Screen, back glass and body are free of any cracks",
      "LCD screen is free of any major blemishes or discoloration",
      "Original Battery above 80% capacity",
      "All buttons are present and fully functional (power, home, ringer, mute, and/or volume)",
      "All features are fully functional (WiFi, touchscreen, mic, speaker, earpiece, camera, etc.)",
      "Clear ESN/ IMEI for activation by a new user. Find my iPhone must be turned off.",
      "Please Note: Any iPhone reported as lost or stolen, locked to an iCloud or Find My iPhone account, or attached to an MDM / DEP / or IT Policy is not eligible for trade-in.",
    ],
  },
  {
    name: "Poor",
    subName:
      "Your device is in this condition if ANY of the following are true:",
    description: [
      "iPhone powers on and holds charge but has one or more defects:",
      "Battery capacity under 80% or displays service battery message",
      "Blemishes on the LCD screen (i.e. discoloration, bruising, dead pixels, white spots, burn-in)",
      "Missing or defective buttons (power, home, touch ID, ringer, mute, and/or volume)",
      "Faulty speaker, earpiece, camera, mic, WiFi, and/or touchscreen",
      "We do not accept any device that is iCloud locked, has 'Find My iPhone' active, or is attached to an MDM / DEP / or IT Policy. For instructions on how to remove iCloud lock from a device that powers on, click here. For instructions on how to remove iCloud lock from a device that does not power on, click here.",

      "Please Note: Any device found to be reported as lost or stolen is not eligible for trade-in and will be immediately turned over to local law enforcement so it cannot be returned to you.",
      "Please Note: We do not accept any device that is missing critical components such as an LCD screen, digitizer, motherboard, or logic board. If your phone is found to be missing any of these necessary parts, it will be recycled responsibly and no payment will be issued.",
    ],
  },
  {
    name: "Broken",
    subName:
      "Your device is in this condition if ANY of the following are true:",
    description: [
      "Does NOT power on or hold a charge",
      "Water damaged",
      "Stuck / Frozen on iTunes or Drained Battery Image",
      "Does NOT boot to main menu",
      "We do not accept any device that is iCloud locked or has 'Find My iPhone' active. For instructions on how to remove iCloud lock from a device that powers on, click here. For instructions on how to remove iCloud lock from a device that does not power on, click here.",
      "Please Note: Any device found to be reported as lost or stolen is not eligible for trade-in and will be immediately turned over to local law enforcement so it cannot be returned to you.",
      "Please Note: We do not accept any device that is missing critical components such as an LCD screen, digitizer, motherboard, or logic board. If your phone is found to be missing any of these necessary parts, it will be recycled responsibly and no payment will be issued.",
      "Broken, cracked, or damaged LCD screen, glass, or touchscreen",
      "Broken or cracked hardware (charging ports, casing, etc.)",
      "Bad ESN / IMEl and is not clear for activation by a new user",
    ],
  },
];
const Attributes = ({
  content,
  handleSubmitAtt,
  setConnectivity,
  setCapacity,
  setConditions,
  setSizes,
  setSelectedAttributes
}: any) => {
  const [openConnectivity, setOpenConnectivity] = useState(true);
  const [openCapacity, setOpenCapacity] = useState(false);
  const [openConditions, setOpenConditions] = useState(false);
  const [openSizes, setOpenSizes] = useState(false);

  const [connectivity1, setConnectivity1] = useState("");
  const [capacity1, setCapacity1] = useState("");
  const [conditions1, setConditions1] = useState("");
  const [sizes1, setSizes1] = useState("");

  const [isDisable, setIsDisable] = useState(true);

  const handleSubmit = () => {
    // Initialize an empty array
    const att = [];

    if (connectivity1) att.push(connectivity1);
    if (capacity1) att.push(capacity1);
    if (conditions1) att.push(conditions1);
    if (sizes1) att.push(sizes1);


    const selectedAtt = { connectivity: connectivity1, capacity: capacity1, condition: conditions1, case_size: sizes1 };
    setSelectedAttributes(selectedAtt)
    const attString = att.join(", ");
    handleSubmitAtt(attString, selectedAtt);
    console.log(attString);
  };


  const updatedData =content.conditions.length > 0 && content.conditions.map((item1: { title    : string; }) => {
    const matchingData2 = data2.find(item2 => item2.name === item1.title    );

    
    
    if (matchingData2) {
      return {
        ...item1,  // Keep the other properties from data1
        subName:matchingData2.subName,
        description: matchingData2.description // Replace description with the one from data2
      };
    }
    return item1;
  });
  
  console.log("updatedData",updatedData);


  return (
    <div className="  ">
      {content.connectivity.length > 0 && (
        <Collapsible
          open={openConnectivity}
          setOpen={setOpenConnectivity}
          title="Connectivity"
          selectedAtt={connectivity1}
          content={content.connectivity}
          contentClick={(item: any) => {
            setConnectivity(item.id);
            setConnectivity1(item.name);
            setOpenCapacity(true);
            setOpenSizes(true);
          }}
        />
      )}

      {content.case_size.length > 0 && (
        <Collapsible
          open={openSizes}
          setOpen={setOpenSizes}
          title="Case Size"
          selectedAtt={sizes1}
          content={content.case_size}
          contentClick={(item: any) => {
            setSizes(item.id);
            setSizes1(item.size);
            setOpenCapacity(true);
            setOpenConditions(true);
          }}
        />
      )}

      {content.capacity.length > 0 && (
        <Collapsible
          open={openCapacity}
          setOpen={setOpenCapacity}
          title="Capacity"
          selectedAtt={capacity1}
          content={content.capacity}
          contentClick={(item: any) => {
            setCapacity(item.id);
            setCapacity1(item.size);
            setOpenConditions(true);
          }}
        />
      )}

      {content.conditions.length > 0 && (
        <ConditionsCollapsible
          open={openConditions}
          setOpen={setOpenConditions}
          title="Conditions"
          selectedAtt={conditions1}
          content={updatedData}
          contentClick={(item: any) => {
            setConditions(item.id);
            setConditions1(item.title);
            setOpenConditions(false);
            setIsDisable(false);
          }}
        />
      )}

      <button
        onClick={handleSubmit}
        disabled={isDisable}
        className={` font-base text-sm font-medium md:w-1/6 rounded-lg py-3 px-4 mt-3 text-white   ${
          isDisable
            ? " bg-slate-400"
            : "hover:bg-green-400 bg-blue-400 rounded-lg"
        }`}
      >
        Confirm
      </button>
    </div>
  );
};

export default Attributes;
