const Hero = () => {
  return (
    <div className="flex justify-between flex-col items-center mb-5">
      <div className="w-full md:w-5/6 flex justify-between flex-col items-center">
        <h1 className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
          Transform Your Old Tech into Value with Cell My Phone
        </h1>
        <h1 className=" text-justify sm:text-center font-light text-lg leading-tight mt-4">
          Sell your devices to Techy and experience the simplicity of our
          instant, fair, and transparent trade-in process. We offer competitive
          prices and immediate payments in cash or store credit.
        </h1>
      </div>
    </div>
  );
};

export default Hero;
