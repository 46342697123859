type Variant = {
    connectivity: { name: any };
    capacity: { size: any };
    condition: { title: any };
    case_size: { size: any };
  };
  
  type SelectedAttributes = {
    connectivity?: any;
    capacity?: any;
    condition?: any;
    case_size?: any;
  };
  
 export const filterVariants = (
    variants: Variant[],
    selectedAttributes: SelectedAttributes
  ): Variant | undefined => {
    return variants.find((item) => {
      return (
        (!selectedAttributes.connectivity ||
          item.connectivity.name === selectedAttributes.connectivity) &&
        (!selectedAttributes.capacity ||
          (item.capacity && item.capacity.size === selectedAttributes.capacity)) &&
        (!selectedAttributes.condition ||
          item.condition.title === selectedAttributes.condition) &&
        (!selectedAttributes.case_size ||
          (item.case_size && item.case_size.size === selectedAttributes.case_size))
      );
    });
  };
  
  // Example usage
  
  