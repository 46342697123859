import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const Categories = async () => {
  try {
    const response = await axios.get(`${API_URL}/categories/`); 
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};

export const SubCategories = async (id:number) => {
  try {
    const response = await axios.get(`${API_URL}/categories/${id}/subcategories/`); 
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};

export const Products = async (id:number) => {
  try {
    const response = await axios.get(`${API_URL}/subcategories/${id}/products/`); 
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};


export const orderCreate = async (data: any) => {
  console.log(`${API_URL}/create_order_with_items/`);
  
  try {
    const response = await axios.post(`${API_URL}/create_order_with_items/`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};

// export const orderItemsPost = async (id: number, data: any) => {
//   try {
//     const response = await axios.post(
//       `${API_URL}/order/${id}/add_items/`,
//       data
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error creating model entries:", error);
//     throw error;
//   }
// };

export const SyncVariantToZoho = async (id: number, data: any) => {
  try {
    const response = await axios.post(
      `${API_URL}/sync_variant_to_zoho/${id}/`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};

export const purchaseOrderToZoho = async (order_id: number, billing_address_id: any) => {
  try {
    const response = await axios.post(
      `${API_URL}/purchase_order_to_zoho/${order_id}/${billing_address_id}/`
    );
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};

export const createEshipOrder = async (order_id: number) => {
  try {
    const response = await axios.post(
      `${API_URL}/create_eship_order/${order_id}/`
    );
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};

export const createEshipOrderShipment = async (order_id: number) => {
  try {
    // create_eship_order/<int:django_order_id>/shipment/
    console.log("111",`${API_URL}/create_eship_order/${order_id}/shipment/`);
    
    const response = await axios.post(
      `${API_URL}/create_eship_order/${order_id}/shipment/`
    );
    return response.data;
  } catch (error) {
    console.error("Error creating model entries:", error);
    throw error;
  }
};
