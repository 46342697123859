import { on } from 'events'
import React, { FC } from 'react'

const BackButton:FC<any> = ({onClick}) => {
  return (
    <div id="back-to-categories-button" className=" mb-10">
    <button
      onClick={onClick}
      className="backButton bg-primary"
      id="backToCategories"
    >
      <span className="IconContainer">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </span>
      <p className="text">Back</p>
    </button>
    {/* <p className=" text-sky-500 font-semibold text-2xl  ml-3">{{ category.name }}</p> */}
  </div>
  )
}

export default BackButton