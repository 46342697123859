const NotFound = () => {
  return (
    <div className="flex items-end justify-center text-center w-full h-20">
      <p className=" font-semibold text-center text-lg text-slate-500">
        Not found
      </p>
    </div>
  );
};

export default NotFound;
