import React, { FC } from "react";

export const Card: FC<any> = ({ item }) => {
  return (
    //   <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg transition-all duration-300 transform  hover:-translate-y-2 hover:opacity-360">
    //     <a href="#">
    //       <img
    //         className="rounded-t-lg"
    //         src={`http://127.0.0.1:8000${item.image}`}
    //         alt=""
    //       />
    //     </a>
    //     <div className="p-5">
    //       <a href="#">
    //         <h5 className="mb-2 text-xl text-center font-bold text-black">
    //           {item.name}
    //         </h5>
    //       </a>
    //     </div>
    //   </div>

    /* From Uiverse.io by Javierrocadev */
    <div className="bg-gradient-to-r from-blue-400  to-green-400 rounded-2xl shadow-sm shadow-gray-300">
      <div className="group overflow-hidden relative after:duration-500 before:duration-500  duration-500 hover:after:duration-500 hover:after:translate-x-24 hover:before:translate-y-12 hover:before:-translate-x-32 hover:duration-500 after:absolute  after:bg-blue-400 after:rounded-full  after:blur-xl after:bottom-32 after:right-16 after:w-12 after:h-12  before:absolute before:w-20 before:h-20 before:bg-blue-400 before:rounded-full  before:blur-xl before:top-20 before:right-16   hover:rotate-3 flex justify-center items-center  origin-bottom-right bg-white rounded-2xl outline outline-slate-200 -outline-offset-8">
        <div className="z-10 flex flex-col items-center gap-2">
         
            <img
              className="rounded-t-lg"
              src={`http://127.0.0.1:8000${item.image}`}
              alt=""
            />
      
          <div className="p-2 md:p-3 lg:p-5">
           
              <h5 className="mb-2 text-sm md:text-base lg:text-xl text-center font-bold text-black">
                {item.name}
              </h5>
          
          </div>
        </div>
      </div>
    </div>

    // <div className="card">
    //   <div className="card-info">
    //     <div className="flex-col bg-white rounded-lg">
    //       <img
    //         className="rounded-t-lg"
    //         src={`http://127.0.0.1:8000${item.image}`}
    //         alt=""
    //       />
    //         <div className="p-5">
    //       <a href="#">
    //      <h5 className="mb-2 text-xl text-center font-bold text-black">
    //          {item.name}
    //         </h5>
    //      </a>
    //   </div>
    //     </div>
    //   </div>
    // </div>
  );
};


export const ProductCard: FC<any> = ({ item, isProduct }) => {
console.log(":::::",item);

  return (
    <div
      key={item.id}
      className="round bg-white border border-gray-200 shadow shadow-slate-300 rounded-lg cursor-pointer  flex flex-col p-3 md:p-6 transition-all duration-300 transform  hover:-translate-y-2 hover:opacity-360"
    >
      <div className="h-28 sm:h-32 md:h-32 w-full flex items-center  ">
        <img
          src={`http://127.0.0.1:8000${item.image}`}
          alt="Smartphone"
          className=" rounded-t-lg object-contain h-[130px] md:h-[150px] w-full"
        />
      </div>
      <h2 className="text-left font-semibold text-sm md:text-xl mt-3 sm:mt-8">
        {item.name}
      </h2>
      {/* <h2 className="text-left font-light text-xs md:text-sm text-slate-400 ">
        Price: ${item.price}
      </h2> */}
    </div>
  );
};
