import { FC } from "react";

const PaymentGateways: FC<any> = ({ paymentMethod, setPaymentMethod }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-10">
      {["Paypal", "Donate", "Credit Card", "Debit Card"].map(
        (payment, index) => (
          <div
            key={payment}
            onClick={() => setPaymentMethod(index + 1)}
            className={`border-2 cursor-pointer h-24  ${
              paymentMethod === index + 1
                ? "border-blue-400 bg-blue-400 text-white"
                : "border-slate-300 bg-gray-50 text-black"
            } hover:border-blue-400 hover:bg-blue-400 hover:text-white rounded-md p-5 flex flex-col justify-center items-center`}
          >
            {payment === "Paypal" ? (
              <img
                src={"/paypal.png"}
                alt="demo"
                className="w-10 mb-2 h-10  object-contain"
              />
            ) : payment === "Debit Card" ? (
              <img
                src={"/credit-card.png"}
                alt="asd"
                className="w-12 h-12 object-contain"
              />
            ) : payment === "Credit Card" ? (
              <img
                src={"/atm-card.png"}
                alt="Description"
                className="w-12 h-12 object-contain"
              />
            ) : (
              <img
                src={"/donate.png"}
                alt="dd"
                className="w-12 h-12 object-contain"
              />
            )}

            <p className="text-sm font-semibold">{payment}</p>
          </div>
        )
      )}
    </div>
  );
};

export default PaymentGateways;
