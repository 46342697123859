import React from "react";

const ProgressBar = ({ screen }: any) => {
  console.log("/////////", screen);

  return (
    <ol className="flex items-center w-full lg:w-3/5 ">
      <li
        className={`flex w-full items-center text-blue-400 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b ${
          [
            "categories",
            "sub-categories",
            "products",
            "attributes",
            "email",
            "get-paid",
            "mail-in",
            "list",
            "checkout",
          ].includes(screen)
            ? "after:border-blue-400"
            : "after:border-slate-400"
        } after:border-4 after:inline-block `}
      >
        <span className="flex items-center justify-center w-10 h-10 bg-blue-400 rounded-full lg:h-10 lg:w-10  shrink-0">
          <svg
            className="w-3.5 h-3.5 text-blue-600 lg:w-4 lg:h-4 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 12"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5.917 5.724 10.5 15 1.5"
            />
          </svg>
        </span>
      </li>
      <li
        className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b ${
          ["list", "checkout", "get-paid"].includes(screen)
            ? "after:border-blue-400"
            : "after:border-slate-400"
        } after:border-4 after:inline-block `}
      >
        <span
          className={`flex items-center justify-center w-8 h-8 text-xl  text-white ${
            ["list", "checkout", "get-paid"].includes(screen)
              ? "bg-blue-400"
              : "bg-slate-400"
          } rounded-full lg:h-10 lg:w-10  shrink-0`}
        >
          {["list", "checkout", "get-paid"].includes(screen) ? (
            <svg
              className="w-3.5 h-3.5 text-blue-600 lg:w-4 lg:h-4 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
          ) : (
            "◉"
          )}
        </span>
      </li>
      <li className="flex items-center ">
        <span
          className={`flex items-center justify-center w-8 h-8 text-xl ${
            ["checkout", "get-paid"].includes(screen)
              ? "bg-blue-400"
              : "bg-slate-400"
          } rounded-full lg:h-10 lg:w-10  text-white shrink-0`}
        >
          {["get-paid"].includes(screen) ? (
            <svg
              className="w-3.5 h-3.5 text-blue-600 lg:w-4 lg:h-4 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 12"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5.917 5.724 10.5 15 1.5"
              />
            </svg>
          ) : (
            "◉"
          )}
        </span>
      </li>
    </ol>
  );
};

export default ProgressBar;
