import { FC } from "react"


const MailIn:FC<any> = ({onClick}) => {
  return (
    <div onClick={onClick} className="flex items-center justify-center ">
    <div className="bg-white shadow border-2 cursor-pointer border-blue-400 hover:border-blue-500 p-12 md:w-3/6 rounded-md flex items-center">
      <svg
        className="group-hover:ease-in-out transition duration-700 cursor-pointer h-24 w-24 object-center object-cover  group-hover:stroke-blue-400 fill-blue-400 group-hover:-translate-y-12 group-hover:-skew-y-12 group-hover:skew-x-12"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
      >
        <g data-name="open box">
          <path d="M29.86 11.49 27.1 6.88A1.85 1.85 0 0 0 25.54 6H6.46a1.85 1.85 0 0 0-1.56.88l-2.76 4.61A1 1 0 0 0 3 13h2v11.76A2.25 2.25 0 0 0 7.25 27h17.5A2.25 2.25 0 0 0 27 24.76V13h2a1 1 0 0 0 .86-1.51zM6.57 8h7l-1.2 3h-7.6zM26 11a1 1 0 0 0-1 1v12.76a.25.25 0 0 1-.25.24H7.25a.25.25 0 0 1-.25-.24V13h6a1 1 0 0 0 .93-.63L15 9.69V22a1 1 0 0 0 2 0V9.69l1.07 2.68A1 1 0 0 0 19 13h3a1 1 0 0 0 0-2h-2.32l-1.2-3h7l1.8 3z" />
          <path d="M10 22H9a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z" />
        </g>
      </svg>
      <p className=" font-semibold text-5xl text-blue-400 mt-3 ml-2">
        Mail In
      </p>
    </div>
  </div>
  )
}

export default MailIn