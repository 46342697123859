import { FC } from "react"


const ListButtons:FC<any> = ({setScreen}) => {
  return (
    <div>
    <div className=" flex ">
      <button
        onClick={() => setScreen("categories")}
        className="bg-transparent mt-0 mr-3 w-full hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
      >
        Add Another
      </button>
      <button
        onClick={() => setScreen("checkout")}
        className="bg-blue-400 mt-0 w-full ml-3 hover:bg-blue-500 text-white font-semibold hover:text-white py-2 px-4 border border-blue-400 hover:border-transparent rounded"
      >
        Continue
      </button>
    </div>
  </div>
  )
}

export default ListButtons