import { FC } from "react";
import { useFormik } from "formik";
import { emailValidationSchema } from "../services/validations";

interface EmailProps {
  email: string;
  setEmail: (email: string) => void;
  emailSave: () => void;
}

const Email: FC<EmailProps> = ({ email, setEmail, emailSave }) => {
  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      setEmail(values.email);
      emailSave()
    },
  });
  
  return (
    <>
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Your Email
      </label>
      <div className="relative mb-6">
        <div className="absolute inset-y-0 start-0 mt-5 ps-3.5 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 16"
          >
            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
          </svg>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <input
            type="text"
            name="email"
            id="input-group-1"
            className={`bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 ${
              formik.errors.email && formik.touched.email
                ? "border-red-500 focus:ring-red-500"
                : ""
            }`}
            placeholder="user@example.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email && (
            <p className="mt-1 text-sm text-red-500">{formik.errors.email}</p>
          )}
          <button
            type="submit"
            className="rounded-lg font-base text-sm font-medium md:w-1/6  py-3 px-4 md:mt-3 text-white bg-blue-400 hover:bg-green-400"
          >
            Save Email
          </button>
        </form>
      </div>
    </>
  );
};

export default Email;
